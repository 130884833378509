<template>
  <div class="shopping-bag-list__tips">
    <!-- sfs商品提示 -->
    <div 
      v-if="sfsProductsTipsVisible"
      class="shopping-bag__sfs shopping-bag-list__tips-item"
    >
      <div class="shopping-bag__sfs-left">
        {{ language.SHEIN_KEY_PC_32654 }}
      </div>
      <SAdapterText
        class="shopping-bag__sfs-right"
        min-size="10"
        lines="2"
        :text="language.SHEIN_KEY_PC_32655"
      />
    </div>
    <!-- 大件海陆运提示 -->
    <div 
      v-if="largeShipTips" 
      class="shopping-bag__large shopping-bag-list__tips-item"
    >
      <Icon
        :name="lagreShipInfo && lagreShipInfo.largeShipIcon"
        :size="'16px'"
        :color="'#198055'"
      />
      <span 
        class="shopping-bag__large-text" 
        v-html="largeShipTips"
      >
      </span>
    </div>
    <!-- 敏感品提示 -->
    <div
      v-if="showSensitiveTips" 
      class="shopping-bag__sensitive shopping-bag-list__tips-item"
    >
      <Icon
        class="shopping-bag__sensitive_icon"
        :name="'sui_icon_sensitive_16px'"
        :size="'16px'"
        :color="'#CFA027'"
      />
      <span 
        class="shopping-bag__tips_flex"
        v-html="language.SHEIN_KEY_PC_28259"
      ></span>
    </div>
    <!-- qs提示 -->
    <div 
      v-if="announcements.length && quickShipCheckoutStatus.quickShipLabelText"
      :class="['shopping-bag__tips', 'shopping-bag-list__tips-item', quickShipCheckoutStatus.quickShipStyle && 'shopping-bag__tips-bg']"
    >
      <div
        v-for="(item, index) in announcements"
        :key="index"
        :class="['shopping-bag__tips_row']"
      >
        <div
          v-if="quickShipCheckoutStatus.quickShipStyle"
          class="shopping-bag_newContainer"
          @click.stop="showQuickPop(item?.icon?.type === 'quickShip')"
        >
          <span :class="['shopping-bag__tips-p0']">
            <s-label
              v-if="item.label" 
              :type="item.labelType"
            >
              {{ item.label }}
            </s-label>
            <quickShipLabel
              v-if="item?.icon?.type === 'quickShip'"
              :text="item?.icon?.label"
              :style-config="item?.icon?.style"
              :show-line="true"
            />
            <Icon
              v-else-if="item.icon"
              class="shopping-bag__tips_icon"
              :name="item.icon.name"
              :size="item.icon.size"
              :color="item.icon.color"
            />
          </span>
          <span
            v-if="item?.icon?.type === 'quickShip'"
            :ref="quickShipTextRef"
            :class="['shopping-bag__tips_flex', 'shopping-bag__tips-line-limit']"
            :style="{lineHeight: `${quickShipLineHeight}px`,'text-align': 'left', 'color': '#198055', 'margin-left': '6px' }"
            v-html="item.text"
          >
          </span>
          <span
            v-else
            :class="['shopping-bag__tips_flex']" 
            v-html="item.text"
          ></span>
        </div>
        <div 
          v-else
          class="shopping-bag-list__qs"
        >
          <s-label
            v-if="item.label" 
            class="shopping-bag-list__qs-icon"
            :type="item.labelType"
          >
            {{ item.label }}
          </s-label>
          <Icon
            v-else-if="item.icon"
            class="shopping-bag-list__qs-icon"
            :name="item.icon.name"
            :size="item.icon.size"
            :color="item.icon.color"
          />
          <div 
            class="shopping-bag-list__qs-text" 
            v-html="item.text"
          ></div>
        </div>
      </div>
    </div>
    <!-- 不可退提示 -->
    <div 
      v-if="showNonRefundableTips"
      class="shopping-bag-list__refundable shopping-bag-list__tips-item"
      @click="nonRefundableVisible = true"
    >
      <div class="shopping-bag-list__refundable-left">
        <Icon
          name="sui_icon_information_supplement_14px"
          size="16px"
          color="#959595"
        />
        <span class="shopping-bag-list__refundable-text">{{ language.SHEIN_KEY_PC_32344 }}</span>
      </div>
      <Icon 
        name="sui_icon_more_right_12px_1" 
        size="12px" 
        color="#959595"
      />
      <!-- 不可退商品弹窗 -->
      <NonRefundableDialog
        v-model:visible="nonRefundableVisible"
        :cart-modal-list="cartModalList"
      />
    </div>
  </div>
</template>

<script setup>
import { template } from '@shein/common-function'
import { isProductRefundable } from 'public/src/pages/checkout/utils.js'

import quickShipLabel from 'public/src/pages/checkout/components/atom/quickShipLabel.vue'
import NonRefundableDialog from './NonRefundableDialog.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SAdapterText } from '@shein-aidc/sui-adapter-text'
import { useGetGuickShip } from 'public/src/pages/checkout/hooks/useQuickShip.js'

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

const props = defineProps({
  mallCode: {
    type: [String, Number],
    default: ''
  },
  checkoutScene: {
    type: String,
    default: ''
  },
  defaultShipping: {
    type: Array,
    default: () => []
  },
  quickShipCheckoutStatus: {
    type: Object,
    default: () => {}
  },
  descQuickShipTime: {
    type: String,
    default: ''
  },
  lagreShipInfo: {
    type: Object,
    default: () => {}
  },
  cartModalList: {
    type: Array,
    default: () => []
  },
  drawerOpened: {
    type: Boolean,
    default: false
  }
})

const { qsReplenishDes } = useGetGuickShip({ mallCode: props.mallCode })

const quickShipTextRef = ref('EffiencyBagList~vueshopping-bag__tips_flex')
const quickShipLineHeight = ref(14)
const nonRefundableVisible = ref(false)

const store = useStore()
const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const SiteUID = computed(() => store.getters.SiteUID)
const showCartRefundByAbt = computed(() => store.getters.showCartRefundByAbt)
const CartOrderSFSVisual = computed(() => store.getters.CartOrderSFSVisual)

// 当前mall是否有qs
const currentMallisQs = computed(() => {
  const { qs_mall_list_all } = store?.state?.qsMallList ?? {}
  const findData = qs_mall_list_all.find(f => f?.mall_code == props?.mallCode)
  return findData && findData?.product_quickship_tag
})
const sfsProductsTipsVisible = computed(() => {
  // 仅下单页展示
  if (props.checkoutScene != 'Checkout') return false
  // 购物袋打开后再展示，否则 SAdapterText 组件不生效
  if (!props.drawerOpened) return false
  const sfsProducts = props.cartModalList.filter(item => item.sfs_flag == 1)
  return CartOrderSFSVisual.value.visual_tabs && 
    CartOrderSFSVisual.value.visual_benefit_point && 
    sfsProducts.length !== 0 && !currentMallisQs.value
})

const largeShipTips = computed(() => {
  // 当前有大件商品且时效文案不为空时展示
  if(!!props?.lagreShipInfo?.largeShipTimeDesc && !!props?.lagreShipInfo?.largeShipGoods?.length) {
    const num = props?.lagreShipInfo?.largeShipGoods.reduce((prev, next) => prev += +next?.quantity, 0)
    return template(num, props?.lagreShipInfo?.largeShipTitle, language.value?.SHEIN_KEY_PC_31616)
  }

  return ''
})

const showSensitiveTips = computed(() => {
  let show = false
  if(props.checkoutScene != 'Checkout') {
    // 二次下单页
    checkout.value?.results?.mallShippingOrderMethod?.mall_list?.forEach(item => {
      if (item.mall_code == props.mallCode) {
        show = !!item.shipping_methods[0]?.cmmdty_infos?.length
      }
    })
  } else {
    // 下单页
    props.defaultShipping?.forEach(item => {
      if (item.mall_code == props.mallCode) {
        show = !!item.shipping_method.cmmdty_infos?.length
      }
    })
  }
  
  return show
})

const announcements = computed(() => {
  let tips = []
  if(props.quickShipCheckoutStatus.quickShipStyle){
    if (props.descQuickShipTime) {
      tips.push({
        icon: {
          type: 'quickShip',
          style: {
            'color': '#198055',
            'background-color': 'transparent',
            'font-size': '12px',
            'icon-size': '16px',
            'font-weight': '700',
            'padding-left': 0,
          },
          label: props.quickShipCheckoutStatus.quickShipLabelText
        },
        text: qsReplenishDes.value
      })
    }
  } else {
    if (qsReplenishDes.value) {
      tips.push({
        label: props.quickShipCheckoutStatus.quickShipLabelText,
        labelType: 'success',
        text: qsReplenishDes.value
      })
    }
  }
  return tips
})

const showNonRefundableTips = computed(() => {
  // 仅下单页展示
  return props.checkoutScene == 'Checkout' && 
    showCartRefundByAbt.value &&
    props.cartModalList.some(item => isProductRefundable(SiteUID.value, item).nonRefundable)
})

const showQuickPop = (flag) => { 
  flag &&  store.commit('assignState', {
    isShowQuickPop: true
  })
}
</script>

<style lang="less" scoped>
.shopping-bag-list__tips {
  .shopping-bag-list__tips-item {
    margin-top: 8px;
  }

  .shopping-bag__sfs {
    border-radius: 2px;
    border: 0.5px solid rgba(25, 128, 85, 0.20);
    background: #ECFCF3;
    display: flex;
    align-items: center;
    padding: 7px 8px;
    margin-top: 8px;
    color: #198055;
    font-family: "SF Pro";
    font-size: 12px;
    line-height: 1.2;
    
    .shopping-bag__sfs-left {
      font-weight: 590;
      padding-right: 8px;
      margin-right: 8px;
      white-space: nowrap;
      border-right: 1px solid rgba(25, 128, 85, 0.40)
    }

    .shopping-bag__sfs-right {
      text-align: left;
    }
  }

  .shopping-bag__large {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    border: 0.5px solid #FFE2D3;
    background: #FFF6F3;
    text-align: left;

    .shopping-bag__large-text {
      margin-left: 10px;
      font-size: 12px;
      color: @sui_color_highlight;
    }
  }

  .shopping-bag__sensitive {
    text-align: initial;
    line-height: 17px;
  }
  .shopping-bag__sensitive_icon {
    margin-right: .1rem;
  }
  
  .shopping-bag-list__qs {
    display: flex;
    align-items: flex-start;
  }
  .shopping-bag-list__qs-icon {
    margin-right: 16px;
  }
  .shopping-bag-list__qs-text {
    color: #666;
    font-family: SF Pro;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
  }
  .shopping-bag__tips-bg{
    background: #ECFCF3;
    padding: 7px 8px;
    margin: 8px 0 0;
    border: 1px solid #BADECD;
    border-radius: 3px;
  }
  .shopping-bag_newContainer{
    // display: inline;
    margin-bottom: 3px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .shopping-bag__tips-p0{
    display: inline-flex;
    padding-right: 0;
    padding-top: 0;
    margin-right: 5/75rem;
    max-width: 40%;
  }

  .shopping-bag__tips-line-limit {
    overflow: hidden;  
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .shopping-bag-list__refundable {
    padding: 8px;
    border-radius: 2px;
    border: 0.5px solid rgba(45, 104, 168, 0.20);
    background: #EBF4FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .shopping-bag-list__refundable-left {
    display: flex;
    align-items: center;
  }

  .shopping-bag-list__refundable-text {
    color: #161616;
    font-size: 12px;
    margin: 0 10px;
    text-align: left;
    line-height: 1.2;
  }
}

.shopping-bag-list__tips-v1 {
  margin-top: 20px;
  .shopping-bag__tips_flex {
    margin-left: 3px;
    text-align: initial;
    font-size: 12px;
    /deep/ span {
      font-style: italic;
      font-weight: 590;
      line-height: normal;
    }
  }
}
.shopping-bag-list__tips-v2 {
  padding: 0 20px;

  .shopping-bag__tips_flex {
    color: #666;
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
}
</style>
